import axios from "axios";
import { API_BASE_URL, getToken } from "../utils/utility";

export default function UserController() {
	//> login
	this.login = async function (mobile, password) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}User/Login`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				data: JSON.stringify({
					mobile: mobile,
					password: password,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (
				error.response &&
				(error.response.status === 400 || error.response.status === 401)
			)
				result.message = error.response.data;
		}
		return result;
	};

	//> register
	this.register = async function (mobile, password, confirmPassword) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}User/Register`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				data: JSON.stringify({
					mobile: mobile,
					password: password,
					confirmPassword: confirmPassword,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (
				error.response &&
				(error.response.status === 400 || error.response.status === 401)
			)
				result.message = error.response.data;
		}
		return result;
	};

	//> forgetPassword
	this.forgetPassword = async function (mobile) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}User/ForgetPassword`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				data: JSON.stringify({
					mobile: mobile,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> changePassword
	this.changePassword = async function (
		mobile,
		verifyCode,
		newPassword,
		confirmNewPassword
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}User/ChangePassword`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				data: JSON.stringify({
					mobile: mobile,
					verifyCode: verifyCode,
					newPassword: newPassword,
					confirmNewPassword: confirmNewPassword,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (
				error.response &&
				(error.response.status === 400 || error.response.status === 401)
			)
				result.message = error.response.data;
		}
		return result;
	};

	//> getProfile
	this.getProfile = async function () {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}User/GetProfile`,
				headers: {
					accept: "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> updateProfile
	this.updateProfile = async function (
		firstName,
		lastName,
		gender,
		birthDate,
		crmCityId,
		email,
		cardNumber,
		isNonIranian,
		nationality,
		nationalId,
		degree,
		jobStatus,
		jobTitle,
		decile
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}User/UpdateProfile`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					firstName: firstName,
					lastName: lastName,
					gender: gender,
					birthDate: birthDate ? new Date(birthDate) : null,
					crmCityId: crmCityId,
					email: email,
					cardNumber: cardNumber,
					isNonIranian: isNonIranian,
					nationality: nationality,
					nationalId: nationalId,
					degree: degree,
					jobStatus: jobStatus,
					jobTitle: jobTitle,
					decile: decile,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> isAdminUser
	this.isAdminUser = async function () {
		let result = false;
		const token = getToken();
		if (!token) return result;
		try {
			const response = await axios.request({
				method: "get",
				url: `${API_BASE_URL}User/IsAdmin`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result = response.data;
		} catch (error) {
			console.warn(error.toString());
			result = false;
		}
		return result;
	};

	//> getMemberShipData
	this.getMemberShipData = async function () {
		let result = {
			isSuccess: true,
			message: "",
			isProfileCompleted: true,
			isMemberShipRemain: true,
			startDate: new Date(),
			expireDate: new Date(),
			remainDays: 100,
			decile: 1,
			payedAmount: 1000,
			mobile : "",
			nationalId : "",
		};
		const token = getToken();
		if (!token) return result;
		try {
			const response = await axios.request({
				method: "get",
				url: `${API_BASE_URL}User/GetMemberShipData`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result = response.data;
		} catch (error) {
			console.warn(error.toString());
			result = { isSuccess: false, message: error.toString() };
		}
		return result;
	};
	//
}
