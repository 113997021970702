import "../styles/buy-service.css";
import React, { useState, useEffect, useRef } from "react";
import ServiceController from "../services/service-controller";
import PaymentController from "../services/payment-controller";
import { Skeleton } from "primereact/skeleton";
import { PrimeIcons } from "primereact/api";
import { getToken, sleep } from "../utils/utility";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";

export default function BuyService(props) {
	const serviceController = new ServiceController();
	const paymentController = new PaymentController();
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [errorData, setErrorData] = useState("");
	const [service, setService] = useState({});
	const [quantity, setQuantity] = useState(1);
	const mapRef = useRef(null);
	//
	const [method, setMethod] = useState(null);
	const [url, setUrl] = useState(null);
	const [formData, setFormData] = useState({});
	//

	const getData = async function () {
		try {
			if (!getToken()) return;
			setIsLoading(true);
			let code = "";
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				code = urlParams.get("code");
			}
			const serviceResult = await serviceController.getServiceByCode(code);
			if (serviceResult.isSuccess === false) throw serviceResult.message;
			setService(serviceResult.value);
		} catch (error) {
			setErrorData(error.toString());
		}
		setIsLoading(false);
	};

	//> changeQuantity
	const changeQuantity = function (value) {
		let newValue = quantity + value;
		if (newValue < 1) newValue = 1;
		if (newValue > 3) newValue = 3;
		setQuantity(newValue);
	};

	//> doPayment
	const doPayment = async function () {
		try {
			setIsSaving(true);
			let createServiceOrderResult = await paymentController.createServiceOrder(
				service.serviceId,
				quantity
			);
			if (createServiceOrderResult.isSuccess === false)
				throw createServiceOrderResult.message;

			let doPaymentResult = await paymentController.doPayment(
				createServiceOrderResult.value
			);
			if (doPaymentResult.isSuccess === false) throw doPaymentResult.message;
			setMethod(doPaymentResult.value.method);
			setUrl(doPaymentResult.value.url);
			setFormData(doPaymentResult.value.formData);
			await sleep(2000);
			document.querySelector("#paymentForm").submit();
		} catch (error) {
			setErrorMessage(error.toString());
		}
		setIsSaving(false);
	};
	//>

	useEffect(() => {
		getData();
	}, []);

	if (!getToken()) {
		let code = "";
		if (window.location.href.indexOf("?") > 0) {
			let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
			code = urlParams.get("code");
		}
		window.location = "/#/login?returnUrl=/membership/?code=" + code;
		return <></>;
	}
	return (
		<div>
			{isLoading === true && (
				<>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-12"></Skeleton>
					</div>
				</>
			)}
			{isLoading === false && errorData && (
				<Message severity="warn" text={errorData} />
			)}

			{isLoading === false && !errorData && (
				<ul className="buy-service">
					<li>
						<label>نام مجموعه ورزشی : </label>
						<b>{service.storeName}</b>
					</li>
					<li>
						<label>نوع خدمات : </label>
						<b>{service.serviceName}</b>
					</li>
					<li>
						<label>قیمت خدمات : </label>
						<b>{service.price?.toLocaleString("fa-IR") + " ریال "} </b>
					</li>
					<li>
						<label>تعداد : </label>
						<div>
							<Button onClick={() => changeQuantity(-1)} severity="danger">
								<i className={PrimeIcons.MINUS}></i>
							</Button>
							<InputText
								value={quantity}
								onChange={(e) => setQuantity(e.target.value)}
								readOnly={true}
							/>
							<Button onClick={() => changeQuantity(1)} severity="success">
								<i className={PrimeIcons.PLUS}></i>
							</Button>
						</div>
					</li>
					<li>
						<label>مجموع قیمت : </label>
						<b>
							{(service.price * quantity).toLocaleString("fa-IR") + " ریال "}{" "}
						</b>
					</li>
					{service.offPercent > 0 && (
						<>
							<li>
								<label> - {service.offPercent} درصد تخفیف : </label>
								<b>
									{(
										service.price *
										quantity *
										service.offPercent *
										0.01
									).toLocaleString("fa-IR") + " ریال "}
								</b>
							</li>
							<li>
								<label>مبلغ باقیمانده : </label>
								<b>
									{(
										service.price * quantity -
										service.price * quantity * service.offPercent * 0.01
									).toLocaleString("fa-IR") + " ریال "}
								</b>
							</li>
						</>
					)}
					{service.levelOffPercent > 0 && (
						<>
							<li>
								<label>
									- {service.levelOffPercent} درصد تخفیف اعضای
									{service.levelName} :
								</label>
								<b>
									{(
										(service.price * quantity -
											service.price * quantity * service.offPercent * 0.01) *
										service.levelOffPercent *
										0.01
									).toLocaleString("fa-IR") + " ریال "}
								</b>
							</li>
						</>
					)}
					<li>
						<label> = مبلغ پرداختی : </label>
						<b>
							{(
								service.price * quantity -
								service.price * quantity * service.offPercent * 0.01 -
								(service.price * quantity -
									service.price * quantity * service.offPercent * 0.01) *
									service.levelOffPercent *
									0.01
							).toLocaleString("fa-IR") + " ریال "}
						</b>
					</li>
					<li>
						<Button
							severity="success"
							disabled={isSaving}
							onClick={() => doPayment()}
						>
							پرداخت آنلاین
						</Button>
					</li>
				</ul>
			)}
			<form id="paymentForm" method={method} action={url}>
				{Object.keys(formData).map((item) => (
					<input type="hidden" name={item} value={formData[item]} />
				))}
			</form>
			{errorMessage && <div className="error">{errorMessage}</div>}
		</div>
	);
}
