import "../styles/edit-services.css";
import "../styles/form.css";
import { useTranslation } from "react-i18next";
import AdminController from "../services/admin-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { API_BASE_URL, getToken } from "../utils/utility.js";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";

export default function EditServices() {
	const { t } = useTranslation();
	const localizer = t;
	const adminController = new AdminController();
	const toast = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(12);
	const [totalRecords, setTotalRecords] = useState(0);
	//
	const [isSaving, setIsSaving] = useState(false);
	const [pageType, setPageType] = useState("list");
	const [searchServiceType, setSearchServiceType] = useState("");
	//
	const [services, setServices] = useState([]);
	const [images, setImages] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	//> Properties
	const [serviceId, setServiceId] = useState(null);
	const [storeId, setStoreId] = useState(null);
	const [code, setCode] = useState(null);
	const [name, setName] = useState(null);
	const [price, setPrice] = useState(0);
	const [status, setStatus] = useState(null);
	//
	const statuses = [
		{ label: "فعال", value: "Active" },
		{ label: "غیر فعال", value: "Deactive" },
	];
	//> useEffect
	useEffect(() => {
		getData();
	}, [storeId, first]);
	//> getData
	const getData = async function () {
		try {
			setIsLoading(true);
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				setStoreId(urlParams.get("storeId"));
			}

			const servicesResult = await adminController.getServices(
				storeId,
				Math.floor(first / 12) + 1,
				12
			);
			if (servicesResult.isSuccess === false) throw servicesResult.message;

			let lastRowNumber = first - 1;
			servicesResult.value.map((item) => (item.index = ++lastRowNumber));

			setServices(servicesResult.value);
			setTotalRecords(servicesResult.total);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};

	//> onPageChange
	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};
	//> resetService
	const resetService = async function () {
		try {
			setPageType("edit");
			setServiceId("00000000-0000-0000-0000-000000000000");
			setCode("");
			setName("");
			setPrice(0);
			setStatus("Active");
			setImages([]);
		} catch (error) {
			alert(error.toString());
		}
	};

	//> editService
	const editService = async function (objService) {
		try {
			setPageType("edit");
			setServiceId(objService.serviceId);
			setStoreId(objService.storeId);
			setCode(objService.code);
			setName(objService.name);
			setPrice(objService.price);
			setStatus(objService.status);
			setImages(objService.images);
		} catch (error) {
			alert(error.toString());
		}
	};
	//> updateService
	const updateService = async function () {
		try {
			let errors = [];

			if (!storeId) errors.push("فروشگاه نامعتبر است");
			if (!code) errors.push("کد را وارد نمایید");
			if (!name) errors.push("نام را وارد نمایید");
			if (!price) errors.push("قیمت را وارد نمایید");
			if (!status) errors.push("وضعیت را انتخاب کنید");

			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: "ثبت خدمات فروشگاه",
						detail: item,
					});
				});
				return;
			}
			//
			setIsSaving(true);
			var updateServiceResult = await adminController.updateService(
				serviceId,
				storeId,
				code,
				name,
				price,
				status
			);
			if (updateServiceResult.isSuccess === false)
				throw updateServiceResult.message;

			toast.current.show({
				severity: "success",
				summary: "ثبت خدمات فروشگاه",
				detail: localizer("ثبت خدمات فروشگاه با موفقیت انجام شد"),
			});
			setPageType("list");
			getData();
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};
	//> uploadFile
	const selectFileForUpload = function () {
		document.querySelector("#frm_fileImage").click();
	};
	//> uploadFile
	const uploadFile = async function () {
		try {
			setIsUploading(true);
			var uploadResult = await adminController.addEntityImage(
				"Services",
				serviceId,
				document.querySelector("#frm_fileImage").files[0]
			);
			if (uploadResult.isSuccess === false) throw uploadResult.message;
			let oldImages = images;
			images.push({
				fileId: uploadResult.value,
				imageUrl: `File/Thumbnail?fileId=${uploadResult.value}&w=150&h=150`,
			});
			setImages(oldImages);
		} catch (error) {
			alert(error.toString());
		}
		setIsUploading(false);
	};

	//> cancelService
	const cancelService = function () {
		setPageType("list");
	};

	//>
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="services">
				{pageType === "list" && (
					<>
						<div>
							<Button
								label="ثبت خدمات فروشگاه جدید"
								icon={PrimeIcons.PLUS_CIRCLE}
								onClick={() => resetService()}
							/>
						</div>
						<table>
							<thead>
								<tr>
									<th>#</th>
									<th>کد</th>
									<th>نام</th>
									<th>قیمت</th>
									<th>وضعیت</th>
								</tr>
							</thead>
							<tbody>
								{services.map((item) => (
									<tr data-servicetype={item.serviceType}>
										<td>{item.index}</td>
										<td>
											<a
												href="javascript:void(0);"
												onClick={() => editService(item)}
											>
												{item.code}
											</a>
										</td>
										<td>{item.name}</td>
										<td>{item.price.toLocaleString("fa-IR")}</td>
										<td>{item.status}</td>
									</tr>
								))}
							</tbody>
						</table>

						<Paginator
							first={first}
							rows={rows}
							totalRecords={totalRecords}
							onPageChange={onPageChange}
						/>
					</>
				)}

				{pageType === "edit" && (
					<div className="form">
						<div>
							<div>
								<label>کد</label>
								<InputText
									value={code}
									onChange={(e) => setCode(e.target.value)}
								/>
							</div>

							<div>
								<label>نام</label>
								<InputText
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
						</div>

						<div>
							<div>
								<label>قیمت</label>
								<InputText
									value={price}
									onChange={(e) => setPrice(e.target.value)}
								/>
							</div>

							<div>
								<label>وضعیت</label>
								<Dropdown
									options={statuses}
									value={status}
									onChange={(e) => setStatus(e.value)}
								/>
							</div>
						</div>

						<div>
							<input
								type="file"
								accept="images"
								id="frm_fileImage"
								onChange={() => uploadFile()}
							/>
							<ul className="images">
								<li onClick={() => selectFileForUpload()}>
									{isUploading === true && <Skeleton></Skeleton>}
									{isUploading === false && (
										<i className={PrimeIcons.PLUS_CIRCLE}></i>
									)}
								</li>
								{images.map((item) => (
									<li>
										<img src={API_BASE_URL + item.imageUrl} alt=""/>
										<a className={PrimeIcons.TRASH}></a>
									</li>
								))}
							</ul>
						</div>

						<div className="buttons">
							<Button
								label="ثبت خدمات فروشگاه"
								onClick={() => updateService("Confirm")}
								severity="success"
								icon={PrimeIcons.SAVE}
								loading={isSaving}
							/>
							<Button
								label="انصراف"
								onClick={() => cancelService()}
								severity="default"
								loading={isSaving}
							/>
						</div>
					</div>
				)}
			</article>
		</>
	);
}
