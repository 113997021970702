import "../styles/locations.css";
import React, { useState, useEffect } from "react";
import ServiceController from "../services/service-controller";

export default function Rooms(props) {
  const serviceController = new ServiceController();
  const [rooms, setRooms] = useState([]);

  const getData = async function () {
    try {
      const roomsResult = await serviceController.getRooms();
      if (roomsResult.isSuccess === false) throw roomsResult.message;
      setRooms(roomsResult.value);
    } catch (error) {
      alert(error.toString());
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <h2 className="head">اماکن اقامتی - بندر انزلی - مشاهده و انتخاب نوع اقامتگاه</h2>
      <ul className="location-rooms">
        {rooms.map((item) => (
          <li>
            <a href={`/#/room-details/?room=${item.name}`} >
              <img src={item.imageUrl} alt=""/>
              <span>{item.name}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
