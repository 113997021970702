import axios from "axios";
import { API_BASE_URL, getToken } from "../utils/utility";

export default function PaymentController() {
	const token = getToken();

	//> viewMembershipPayment
	this.viewMembershipPayment = async function (decile) {
		let result = {
			isSuccess: true,
			message: "Success",
			value: {
				paymentId: "",
				mobile: "",
				nationalId: "",
				baseAmount: 0,
				levelName: "",
				decile: 0,
				offPercent: 0,
				discount: 0,
				totalAmount: 0,
			},
		};
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Payment/ViewMembershipPayment?decile=${decile}`,
				headers: {
					accept: "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> createServiceOrder
	this.createServiceOrder = async function (serviceId, quantity) {
		let result = {
			isSuccess: true,
			message: "Success",
			value: "",
		};
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Payment/CreateServiceOrder`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					serviceId: serviceId,
					quantity: quantity,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> doPayment
	this.doPayment = async function (paymentId) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Payment/DoPayment`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					paymentId: paymentId,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getPayment
	this.getPayment = async function (paymentId) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Payment/GetPayment?paymentId=${paymentId}`,
				headers: {
					accept: "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//
}
