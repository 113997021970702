import "../styles/locations.css";
import React, { useState, useEffect, useRef } from "react";
import NeshanMap from "@neshan-maps-platform/react-openlayers";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import ServiceController from "../services/service-controller";
import { Carousel } from "primereact/carousel";
import { useNavigate } from "react-router-dom";

export default function RoomDetails(props) {
  const mapRef = useRef(null);
  const serviceController = new ServiceController();
  const [roomDetails, setRoomDetails] = useState({});
  const navigate = useNavigate();

  const getData = async function () {
    try {
      const roomDetailsResult = await serviceController.getRoomDetails();
      if (roomDetailsResult.isSuccess === false)
        throw roomDetailsResult.message;
      setRoomDetails(roomDetailsResult.value);
      //window.MyMap = new  Geolocation();
      //mapRef.current?.map.getView().setCenter([roomDetailsResult.value.longitude,roomDetailsResult.value.latitude,]);
    } catch (error) {
      alert(error.toString());
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const imageTemplate = (image) => {
    return (
      <div className="room-image">
        <Image src={image.imageUrl} alt={image.title} preview />
        <span>{image.title}</span>
      </div>
    );
  };

  //> confirmRoom
  const confirmRoom = function () {
    window.location = "/#/add-new-queue";
  };

  //> cancelRoom
  const cancelRoom = function () {
    window.history.go(-1);
  };

  //>
  return (
    <div>
      <h2 className="head">
        {" "}
        اماکن اقامتی - بندر انزلی - ویلا - مشاهده امکانات
      </h2>

      <ol className="room-details">
        <li>
          <label>نوع اتاق</label>
          <span>{roomDetails.name}</span>
        </li>
        <li>
          <label>نام شهر</label>
          <span>{roomDetails.city}</span>
        </li>
        <li>
          <label>آدرس</label>
          <span>{roomDetails.address}</span>
        </li>
      </ol>

      <div className="buttons">
        <Button onClick={(e) => confirmRoom()} severity="success">
          انتخاب اتاق
        </Button>
        <Button onClick={(e) => cancelRoom()} severity="danger">
          بازگشت
        </Button>
      </div>

      <Carousel
        value={roomDetails.images}
        numVisible={1}
        numScroll={1}
        itemTemplate={imageTemplate}
        autoplayInterval={5000}
        circular
      />

      <NeshanMap
        mapKey="web.1c8021a2962c46be925658f642c30c83"
        ref={mapRef}
        center={{
          latitude: 35.7665394,
          longitude: 51.4749824,
        }}
        zoom={14}
        style={{ width: "100%", height: 400 }}
      />
    </div>
  );
}
