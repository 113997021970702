import "../styles/levels.css";
import React from "react";

export default function LevelBronze(props) {

  return (
    <article className="levels">
      <h2>سطح برنزی</h2>
      <h3>عموم مشتریان</h3>
      <div>
        <p>
          مشتریان گرامی می توانند از تمامی خدمات تخفیفی در سایر فروشگاه های طرف
          قرارداد سامانه جامع باشگاه مشتریان کارگران استفاده نمایند .
        </p>
        <p>
          همچنین می توانند از خدمات مجموعه های ورزشی و تفریحی کارگران کشور نیز به صورت عادی بهره مند شوند .
        </p>
        <strong>حق عضویت یکساله : 700 هزار تومان</strong>
        <b>
          با هر خرید از طریق فروشگاه ها و مراکز طرف قرارداد و افزایش امتیاز می
          توانید :
        </b>
        <ul>
          <li>ارتقا به سطح بالاتر و استفاده از امکانات سطح مربوطه</li>
          <li>
            بهرمندی از جوایزی که طی قرعه کشی های منظم مشخص و ارائه می گردد .
          </li>
          <li>بهره مندی از کمپین های فروش لوازم ورزشی با قیمتی بسیار ویژه</li>
          <li>
            بهرمندی از امکان استفاده از تورهای ورزشی ، تفریحی ، زیارتی و سیاحتی
          </li>
          <li>
            بهرمندی از امکان خرید اعتباری و اقساطی در مراکز و فروشگاه های طرف
            قرارداد
          </li>
        </ul>
      </div>
    </article>
  );
}
