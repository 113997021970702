import "../styles/view-service-orders.css";
import AdminController from "../services/admin-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { getToken } from "../utils/utility.js";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { Skeleton } from "primereact/skeleton";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";

export default function ViewServiceOrders() {
	const adminController = new AdminController();
	const toast = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(12);
	const [totalRecords, setTotalRecords] = useState(0);
	//
	const [isSaving, setIsSaving] = useState(false);
	const [pageType, setPageType] = useState("list");
	const [searchStatus, setSearchStatus] = useState("Payed");
	const [isStore, setIsStore] = useState("");
	//
	const [serviceOrders, setServiceOrders] = useState([]);
	const [serviceOrder, setServiceOrder] = useState({});
	//> useEffect
	useEffect(() => {
		getData();
	}, [isStore, searchStatus, first]);
	//> getData
	const getData = async function () {
		try {
			setIsLoading(true);

			let _isStore = false;
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				_isStore = urlParams.get("isStore");
			}
			setIsStore(_isStore);

			const serviceOrdersResult = await adminController.getServiceOrders(
				isStore,
				searchStatus,
				searchText,
				Math.floor(first / 12) + 1,
				12
			);
			if (serviceOrdersResult.isSuccess === false)
				throw serviceOrdersResult.message;
			serviceOrdersResult.value.map((item) => {
				item.createdOn = new Date(item.createdOn);
			});

			let lastRowNumber = first;
			serviceOrdersResult.value.map((item) => (item.index = ++lastRowNumber));

			setServiceOrders(serviceOrdersResult.value);
			setTotalRecords(serviceOrdersResult.total);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};

	//> changeSearchStatus
	const changeSearchStatus = function (status) {
		setSearchStatus(status);
		getData();
	};

	//> onPageChange
	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};

	//> viewServiceOrder
	const viewServiceOrder = async function (objServiceOrder) {
		try {
			setIsLoading(true);
			setPageType("view");
			const serviceOrderResult = await adminController.getServiceOrder(
				isStore,
				objServiceOrder.serviceOrderId
			);
			if (serviceOrderResult.isSuccess === false)
				throw serviceOrderResult.message;
			if (serviceOrderResult.value.birthDate) {
				serviceOrderResult.value.age =
					new Date().getFullYear() -
					new Date(serviceOrderResult.value.birthDate).getFullYear();
			}
			setServiceOrder(serviceOrderResult.value);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};
	//> completeServiceOrder
	const completeServiceOrder = async function () {
		try {
			setIsSaving(true);
			var updateServiceOrderResult = await adminController.completeServiceOrder(
				isStore,
				serviceOrder.serviceOrderId
			);
			if (updateServiceOrderResult.isSuccess === false)
				throw updateServiceOrderResult.message;

			toast.current.show({
				severity: "success",
				summary: "تغییر وضعیت سفارش خدمات",
				detail: "تغییر وضعیت سفارش خدمات با موفقیت انجام شد",
			});
			setPageType("list");
			getData();
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};
	//> cancelServiceOrder
	const cancelServiceOrder = function () {
		setPageType("list");
	};
	//> search
	const search = function (keyCode) {
		if (keyCode !== 13) return;
		setFirst(1);
		getData();
	};
	//>
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="service-orders">
				{pageType === "list" && isLoading === true && (
					<div>
						<Skeleton height="3rem" className="w-12"></Skeleton>
						<div className="mb-1"></div>
						<Skeleton height="3rem" className="w-12"></Skeleton>
						<div className="mb-1"></div>
						<Skeleton height="3rem" className="w-12"></Skeleton>
						<div className="mb-1"></div>
						<Skeleton height="3rem" className="w-12"></Skeleton>
					</div>
				)}
				{pageType === "list" && isLoading === false && (
					<>
						<ul className="status">
							<li
								className={searchStatus === "Payed" ? "active payed" : "payed"}
								onClick={() => changeSearchStatus("Payed")}
							>
								پرداخت شده
							</li>
							<li
								className={
									searchStatus === "Waiting" ? "active waiting" : "waiting"
								}
								onClick={() => changeSearchStatus("Waiting")}
							>
								منتظر پرداخت
							</li>
							<li
								className={
									searchStatus === "Completed"
										? "active completed"
										: "completed"
								}
								onClick={() => changeSearchStatus("Completed")}
							>
								تکمیل شده
							</li>
						</ul>

						<IconField iconPosition="left" style={{ width: 350 }}>
							<InputIcon className={PrimeIcons.SEARCH}></InputIcon>
							<InputText
								placeholder="جستجو..."
								style={{ width: "100%" }}
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
								onKeyUp={(e) => search(e.keyCode)}
							/>
						</IconField>
						<table>
							<thead>
								<tr>
									<th>#</th>
									{searchStatus === "Payed" && <th>کد پیگیری</th>}
									<th>مجموعه ورزشی</th>
									<th>نام خدمات</th>
									<th>کد خدمات</th>
									<th>نام مشتری</th>
									<th>موبایل</th>
									<th>کد ملی</th>
									<th>تاریخ ثبت</th>
								</tr>
							</thead>
							<tbody>
								{serviceOrders.map((item) => (
									<tr data-status={item.status}>
										<td>{item.index}</td>
										{searchStatus === "Payed" && (
											<td>
												<a
													href="javascript:void(0);"
													onClick={() => viewServiceOrder(item)}
												>
													{item.trackingNumber}
												</a>
											</td>
										)}
										<td>{item.storeName}</td>
										<td>{item.serviceName}</td>
										<td>{item.serviceCode}</td>
										<td>{item.userFullName}</td>
										<td>{item.mobile}</td>
										<td>{item.nationalId}</td>
										<td>
											{item.createdOn.toLocaleString("fa-IR", {
												dateStyle: "short",
											})}
										</td>
									</tr>
								))}
							</tbody>
						</table>

						<Paginator
							first={first}
							rows={rows}
							totalRecords={totalRecords}
							onPageChange={onPageChange}
						/>
					</>
				)}
				{pageType === "view" && isLoading === true && (
					<div className="flex gap-4 mb-auto">
						<Skeleton height="20rem" className="w-3"></Skeleton>
						<Skeleton height="20rem" className="w-3"></Skeleton>
					</div>
				)}
				{pageType === "view" && isLoading === false && (
					<div className="view-details">
						<h2>مشخصات مشتری</h2>
						<ul className="user-info">
							<li>
								<label>نام مشتری</label>
								<strong>{serviceOrder.userFullName}</strong>
							</li>
							<li>
								<label>موبایل</label>
								<strong>{serviceOrder.mobile}</strong>
							</li>
							<li>
								<label>کد ملی</label>
								<strong>{serviceOrder.nationalId}</strong>
							</li>
							<li>
								<label>سن مشتری</label>
								<strong>{serviceOrder.age}</strong>
							</li>
							<li>
								<label>جنسیت</label>
								<strong>{serviceOrder.gender === true ? "مرد" : "زن"}</strong>
							</li>
						</ul>

						<h2>مشخصات مجموعه ورزشی</h2>
						<ul>
							<li>
								<label>نام مجموعه</label>
								<strong>{serviceOrder.storeName}</strong>
							</li>
							<li>
								<label>تلفن</label>
								<strong>{serviceOrder.storeTel}</strong>
							</li>
							<li>
								<label>آدرس</label>
								<strong>{serviceOrder.address}</strong>
							</li>
						</ul>

						<h2>مشخصات سفارش</h2>
						<ul>
							<li>
								<label>نام خدمات</label>
								<strong>{serviceOrder.serviceName}</strong>
							</li>
							<li>
								<label>کد خدمات</label>
								<strong>{serviceOrder.serviceCode}</strong>
							</li>
							<li>
								<label>قیمت</label>
								<strong>
									{serviceOrder.price.toLocaleString("fa-IR") + " ریال "}
								</strong>
							</li>
							<li>
								<label>تعداد</label>
								<strong>{serviceOrder.quantity.toLocaleString("fa-IR")}</strong>
							</li>
							<li>
								<label>تخفیف</label>
								<strong>
									{serviceOrder.discount.toLocaleString("fa-IR") + " ریال "}
								</strong>
							</li>
							<li>
								<label>مبلغ پرداختی</label>
								<strong>
									{serviceOrder.totalPrice.toLocaleString("fa-IR") + " ریال "}
								</strong>
							</li>
						</ul>
						<div className="col-12 buttons">
							<Button
								label="تایید سفارش"
								onClick={() => completeServiceOrder()}
								severity="success"
								icon={PrimeIcons.SAVE}
								loading={isSaving}
							/>
							<Button
								label="انصراف"
								onClick={() => cancelServiceOrder()}
								severity="default"
								loading={isSaving}
							/>
						</div>
					</div>
				)}
			</article>
		</>
	);
}
