import "../styles/about.css";
import React from "react";

export default function Levels(props) {

  return (
    <div>
      <h3>سطح بندی مشتریان :</h3>
    </div>
  );
}
