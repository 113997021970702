import "../styles/membership.css";
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import UserController from "../services/user-controller";
import { getToken } from "../utils/utility";
import { useNavigate } from "react-router-dom";

export default function MemberShip(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [decile,setDecile] = useState(1);
	const [memberShipData, setMemberShipData] = useState({
		isSuccess: true,
		errorMessage: "",
		isProfileCompleted: true,
		isMemberShipRemain: true,
		startDate: new Date(),
		expireDate: new Date(),
		remainDays: 100,
		decile: 1,
		payedAmount: 1000,
		mobile: "",
		nationalId: "",
	});
	const [errorMessage, setErrorMessage] = useState("");
	//
	const userController = new UserController();

	useEffect(() => {
		getData();
	}, []);

	const getData = async function () {
		try {
			if (!getToken()) return;
			if (window.isBusy === true) return;
			setIsLoading(true);
			window.isBusy = true;
			var result = await userController.getMemberShipData();
			if (result.isSuccess === false) throw result.message;

			if(result.startDate)
				result.startDate = new Date(result.startDate);
			if(result.expireDate)
				result.expireDate = new Date(result.expireDate);

			setMemberShipData(result);
		} catch (error) {
			setErrorMessage(error.toString());
		}
		setIsLoading(false);
		window.isBusy = false;
	};
	//> gotoPayment
	const gotoPayment = function(){
		window.location = "/#/payment?decile=" + decile;
	}

	//>
	if (!getToken()) {
		window.location = "/#/login?returnUrl=/membership";
		return <></>;
	}
	return (
		<article className="membership">
			<div className="header">اطلاعات عضویت</div>
			{memberShipData.isSuccess === false && (
				<div className="error">
					<p>{memberShipData.errorMessage}</p>
				</div>
			)}
			{memberShipData.isSuccess === true &&
				memberShipData.isProfileCompleted === false && (
					<div className="warning">
						<p>
							برای دسترسی به اطلاعات عضویت ابتدا پروفایل خود را تکمیل نمایید
						</p>
						<p>
							برای تکمیل پروفایل
							<a href="/#/profile">اینجا</a>
							را کلیک کنید
						</p>
					</div>
				)}
			{memberShipData.isSuccess === true &&
				memberShipData.isProfileCompleted === true &&
				memberShipData.isMemberShipRemain === true && (
					<div className="success">
						<p>عضویت شما تا {memberShipData.remainDays} دیگر فعال می باشد</p>
						<ul>
							<li>
								<label>شماره موبایل : </label>
								<strong>{memberShipData.mobile}</strong>
							</li>
							<li>
								<label>کد ملی : </label>
								<strong>{memberShipData.nationalId}</strong>
							</li>
							<li>
								<label>تاریخ شروع عضویت : </label>
								<strong>
									{memberShipData.startDate.toLocaleDateString("fa-IR", {
										dateStyle: "short",
									})}
								</strong>
							</li>
							<li>
								<label>تاریخ پایان عضویت : </label>
								<strong>
									{memberShipData.expireDate.toLocaleDateString("fa-IR", {
										dateStyle: "short",
									})}
								</strong>
							</li>
							<li>
								<label>دهک فعلی شما : </label>
								<strong>{memberShipData.decile}</strong>
							</li>
							<li>
								<label>مبلغ پرداختی : </label>
								<strong>
									{memberShipData.payedAmount.toLocaleString("fa-IR")} ریال
								</strong>
							</li>
						</ul>
					</div>
				)}

			{memberShipData.isSuccess === true &&
				memberShipData.isProfileCompleted === true &&
				memberShipData.isMemberShipRemain === false && (
					<div className="info">
						<p>
							برای پرداخت حق عضویت ، دهک خود را انتخاب و بر روی دکمه ادامه کلیک
							نمایید
						</p>
						<ul>
							<li>
								<label>شماره موبایل : </label>
								<strong>{memberShipData.mobile}</strong>
							</li>
							<li>
								<label>کد ملی : </label>
								<strong>{memberShipData.nationalId}</strong>
							</li>
							<li>
								<label>دهک فعلی شما : </label>
								<strong>
									<select
										value={decile}
										onChange={(e) => setDecile(e.target.value)}
									>
										<option value={1}>دهک اول</option>
										<option value={2}>دهک دوم</option>
										<option value={3}>دهک سوم</option>
										<option value={4}>دهک چهارم</option>
										<option value={5}>دهک پنجم</option>
										<option value={6}>دهک ششم</option>
										<option value={7}>دهک هفتم</option>
										<option value={8}>دهک هشتم</option>
										<option value={9}>دهک نهم</option>
										<option value={10}>دهک دهم</option>
									</select>
								</strong>
							</li>
							<li>
								<label></label>
								<button onClick={()=> gotoPayment()}>تایید دهک و ادامه</button>
							</li>
						</ul>
					</div>
				)}
			 
			<div>
				{errorMessage && <div className="text-danger">{errorMessage}</div>}
			</div>
		</article>
	);
}
