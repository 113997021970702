import "../styles/edit-residences.css";
import "../styles/form.css";
import { useTranslation } from "react-i18next";
import AdminController from "../services/admin-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { API_BASE_URL, getToken } from "../utils/utility.js";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import { Editor } from "primereact/editor";
import ServiceController from "../services/service-controller.js";

export default function EditResidences() {
	const { t } = useTranslation();
	const localizer = t;
	const adminController = new AdminController();
	const serviceController = new ServiceController();
	const toast = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	//
	const [residenceId, setResidenceId] = useState("");
	const [stateId, setStateId] = useState("");
	const [cityId, setCityId] = useState("");
	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [latitude, setLatitude] = useState(0.0);
	const [longtitude, setLongtitude] = useState(0.0);
	const [tel, setTel] = useState("");
	const [laws, setLaws] = useState("");
	const [startTime, setStartTime] = useState(0);
	const [endTime, setEndTime] = useState(0);
	const [totalCapacity, setTotalCapacity] = useState(0);
	const [status, setStatus] = useState("");
	//
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
	const [residences, setResidences] = useState([]);
	const [images, setImages] = useState([]);
	//
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(12);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isSaving, setIsSaving] = useState(false);
	const [pageType, setPageType] = useState("list");
	const [isUploading, setIsUploading] = useState(false);
	//
	const statuses = [
		{ value: "Active", text: "فعال" },
		{ value: "DeActive", text: "غیر فعال" },
	];
	//> useEffect
	useEffect(() => {
		getData();
	}, []);
	//> onPageChange
	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};
	//> getData
	const getData = async function () {
		try {
			setIsLoading(true);

			await getStates();

			const residencesResult = await adminController.getResidences(
				Math.floor(first / 12) + 1,
				12
			);
			if (residencesResult.isSuccess === false) throw residencesResult.message;
			residencesResult.value.map((item) => {
				item.startDate = new Date(item.startDate);
				item.endDate = new Date(item.endDate);
			});

			let lastRowNumber = first - 1;
			residencesResult.value.map((item) => (item.index = ++lastRowNumber));

			setResidences(residencesResult.value);
			setTotalRecords(residencesResult.total);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};
	//> resetResidence
	const resetResidence = async function () {
		try {
			setPageType("edit");

			setResidenceId("00000000-0000-0000-0000-000000000000");
			setCityId("");
			setName("");
			setAddress("");
			setLatitude(0);
			setLongtitude(0);
			setTel("");
			setLaws("");
			setStartTime(0);
			setEndTime(0);
			setTotalCapacity(0);
			setStatus("Active");

			setImages([]);
		} catch (error) {
			alert(error.toString());
		}
	};

	//> editResidence
	const editResidence = async function (objResidence) {
		try {
			setPageType("edit");

			setResidenceId(objResidence.residenceId);
			setName(objResidence.name);
			setAddress(objResidence.address);
			setLatitude(objResidence.latitude);
			setLongtitude(objResidence.longtitude);
			setTel(objResidence.tel);
			setLaws(objResidence.laws);
			setStartTime(objResidence.startTime);
			setEndTime(objResidence.endTime);
			setTotalCapacity(objResidence.totalCapacity);
			setStatus(objResidence.status);
			await getCities(objResidence.stateId);
			setCityId(objResidence.cityId);
		} catch (error) {
			alert(error.toString());
		}
	};
	//> updateResidence
	const updateResidence = async function () {
		try {
			let errors = [];

			if (!cityId) errors.push("شهر را وارد نمایید");
			if (!name) errors.push("نام اقامتگاه را وارد نمایید");

			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: "ثبت اقامتگاه",
						detail: item,
					});
				});
				return;
			}
			//
			setIsSaving(true);
			var updateResidenceResult = await adminController.updateResidence(
				residenceId,
				cityId,
				name,
				address,
				latitude,
				longtitude,
				tel,
				laws,
				startTime,
				endTime,
				totalCapacity,
				status
			);
			if (updateResidenceResult.isSuccess === false)
				throw updateResidenceResult.message;

			toast.current.show({
				severity: "success",
				summary: "ثبت اقامتگاه",
				detail: localizer("ثبت اطلاعات اقامتگاه با موفقیت انجام شد"),
			});
			setPageType("list");
			getData();
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};
	//> uploadFile
	const selectFileForUpload = function () {
		document.querySelector("#frm_fileImage").click();
	};
	//> uploadFile
	const uploadFile = async function () {
		try {
			setIsUploading(true);
			var uploadResult = await adminController.addEntityImage(
				"Residences",
				residenceId,
				document.querySelector("#frm_fileImage").files[0]
			);
			if (uploadResult.isSuccess === false) throw uploadResult.message;
			let oldImages = images;
			images.push({
				fileId: uploadResult.value,
				imageUrl: `File/Thumbnail?fileId=${uploadResult.value}&w=150&h=150`,
			});
			setImages(oldImages);
		} catch (error) {
			alert(error.toString());
		}
		setIsUploading(false);
	};

	//> cancelResidence
	const cancelResidence = function () {
		setPageType("list");
	};

	//> getStates
	const getStates = async function () {
		const statesResult = await serviceController.getRegions(1, null);
		setStates(statesResult.value);
	};

	//> getCities
	const getCities = async function (stateId) {
		setStateId(stateId);
		const citiesResult = await serviceController.getRegions(null, stateId);
		setCities(citiesResult.value);
	};

	//>
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="residences">
				{pageType === "list" && (
					<>
						<div>
							<Button
								label="ثبت اقامتگاه جدید"
								icon={PrimeIcons.PLUS_CIRCLE}
								onClick={() => resetResidence()}
							/>
						</div>
						<table>
							<thead>
								<tr>
									<th>#</th>
									<th>نام اقامتگاه</th>
									<th>استان</th>
									<th>شهر</th>
									<th>تلفن</th>
									<th>حداکثر ظرفیت</th>
									<th>محدودیت ها</th>
								</tr>
							</thead>
							<tbody>
								{residences.map((item) => (
									<tr data-status={item.status}>
										<td>{item.index}</td>
										<td>
											<a
												href="javascript:void(0);"
												onClick={() => editResidence(item)}
											>
												{item.name}
											</a>
										</td>
										<td>{item.stateName}</td>
										<td>{item.cityName}</td>
										<td>{item.tel}</td>
										<td>{item.totalCapacity}</td>
										<td>
											<a href={"/#/edit-residence-limits/?residenceId=" + item.residenceId}>{item.totalLimits}</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<Paginator
							first={first}
							rows={rows}
							totalRecords={totalRecords}
							onPageChange={onPageChange}
						/>
					</>
				)}

				{pageType === "edit" && (
					<div className="form">
						<div>
							<div>
								<label>استان</label>
								<Dropdown
									id="stateId"
									className="w-full"
									value={stateId}
									onChange={(e) => getCities(e.value)}
									options={states}
									optionLabel="name"
									optionValue="regionId"
								/>
							</div>
							<div>
								<label>شهر</label>
								<Dropdown
									id="cityId"
									className="w-full"
									value={cityId}
									onChange={(e) => setCityId(e.value)}
									options={cities}
									optionLabel="name"
									optionValue="regionId"
									filter
									emptyMessage="هیچ شهری پیدا نشد"
								/>
							</div>
						</div>
						<div>
							<div>
								<label>نام اقامتگاه</label>
								<InputText
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div>
								<label>تلفن</label>
								<InputText
									value={tel}
									onChange={(e) => setTel(e.target.value)}
								/>
							</div>
						</div>
						<div className="full">
							<div>
								<label>آدرس</label>
								<InputText
									value={address}
									onChange={(e) => setAddress(e.target.value)}
								/>
							</div>
						</div>
						<div>
							<div>
								<label>طول جغرافیایی</label>
								<InputText
									value={longtitude}
									onChange={(e) => setLongtitude(e.target.value)}
								/>
							</div>
							<div>
								<label>عرض جغرافیایی</label>
								<InputText
									value={latitude}
									onChange={(e) => setLatitude(e.target.value)}
								/>
							</div>
						</div>

						<div className="full">
							<label>قوانین و مقررات</label>
							<Editor
								value={laws}
								onTextChange={(e) => setLaws(e.htmlValue)}
								style={{ height: "320px" }}
							/>
							<InputText
								value={laws}
								onChange={(e) => setLaws(e.target.value)}
							/>
						</div>

						<div>
							<div>
								<label>ساعت ورود</label>
								<InputText
									value={startTime}
									onChange={(e) => setStartTime(e.target.value)}
								/>
							</div>

							<div>
								<label>ساعت خروج</label>
								<InputText
									value={endTime}
									onChange={(e) => setEndTime(e.target.value)}
								/>
							</div>
						</div>

						<div>
							<div>
								<label>حداکثر ظرفیت</label>
								<InputText
									value={totalCapacity}
									onChange={(e) => setTotalCapacity(e.target.value)}
								/>
							</div>
							<div>
								<label>وضعیت</label>
								<Dropdown
									id="statuses"
									className="w-full"
									value={status}
									onChange={(e) => setStatus(e.value)}
									options={statuses}
									optionLabel="text"
									optionValue="value"
								/>
							</div>
						</div>

						<div>
							<input
								type="file"
								accept="images"
								id="frm_fileImage"
								onChange={() => uploadFile()}
							/>
							<ul className="images">
								<li onClick={() => selectFileForUpload()}>
									{isUploading === true && <Skeleton></Skeleton>}
									{isUploading === false && (
										<i className={PrimeIcons.PLUS_CIRCLE}></i>
									)}
								</li>
								{images.map((item) => (
									<li>
										<img src={API_BASE_URL + item.imageUrl} alt=""/>
										<a className={PrimeIcons.TRASH}></a>
									</li>
								))}
							</ul>
						</div>

						<div className="buttons">
							<Button
								label="ثبت اقامتگاه"
								onClick={() => updateResidence("Confirm")}
								severity="success"
								icon={PrimeIcons.SAVE}
								loading={isSaving}
							/>
							<Button
								label="انصراف"
								onClick={() => cancelResidence()}
								severity="default"
								loading={isSaving}
							/>
						</div>
					</div>
				)}
			</article>
		</>
	);
}
