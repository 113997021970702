import axios from "axios";
import { API_BASE_URL, getToken } from "../utils/utility";

export default function AminController() {
	const token = getToken();
	//> isAdminUser
	this.isAdminUser = async function () {
		let result = false;
		if (!token) return result;
		try {
			const response = await axios.request({
				method: "get",
				url: `${API_BASE_URL}User/IsAdmin`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result = response.data;
		} catch (error) {
			console.warn(error.toString());
			result = false;
		}
		return result;
	};
	//> getSummary
	this.getSummary = async function (today) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetSummary?isToday=${today}`,
				headers: {
					accept: "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getUsers
	this.getUsers = async function (pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetUsers?pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getUserProfile
	this.getUserProfile = async function (userId) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetUserProfile?userId=${userId}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getLevels
	this.getLevels = async function () {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetLevels`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getStores
	this.getStores = async function (storeType, pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetStores?storeType=${storeType}&pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getServices
	this.getServices = async function (storeId, pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetServices?storeId=${storeId}&pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getServiceOrders
	this.getServiceOrders = async function (
		isStore,
		status,
		searchText,
		pageNumber,
		pagingSize
	) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetServiceOrders?isStore=${isStore}&searchText=${searchText}&status=${status}&pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getServiceOrder
	this.getServiceOrder = async function (isStore, serviceOrderId) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetServiceOrder?isStore=${isStore}&serviceOrderId=${serviceOrderId}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getFoodMenus
	this.getFoodMenus = async function (residenceId, pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetFoodMenus?residenceId=${residenceId}&pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getResidences
	this.getResidences = async function (pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetResidences?pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getResidenceLimits
	this.getResidenceLimits = async function (
		residenceId,
		pageNumber,
		pagingSize
	) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetResidenceLimits?residenceId=${residenceId}&pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getResidenceLimitStates
	this.getResidenceLimitStates = async function (residenceLimitId) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetResidenceLimitStates?residenceLimitId=${residenceLimitId}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getResidenceRoomTypes
	this.getResidenceRoomTypes = async function (
		residenceId,
		pageNumber,
		pagingSize
	) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetResidenceRoomTypes?residenceId=${residenceId}&pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getRooms
	this.getRooms = async function (pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetRooms?pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getRoomTypes
	this.getRoomTypes = async function (pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetRoomTypes?pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> ExportUsers
	this.exportUsers = async function () {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/ExportUsers`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateLevel
	this.updateLevel = async function (
		levelId,
		code,
		name,
		title,
		summary,
		notes,
		minDecile,
		maxDecile,
		offPercent,
		membershipOffPercent,
		sortOrder
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateLevel`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					levelId: levelId,
					code: code,
					name: name,
					title: title,
					summary: summary,
					notes: notes,
					minDecile: minDecile,
					maxDecile: maxDecile,
					offPercent: offPercent,
					membershipOffPercent: membershipOffPercent,
					sortOrder: sortOrder,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateStore
	this.updateStore = async function (
		storeId,
		name,
		rowNumber,
		offPercent,
		summary,
		notes,
		dayOfWork,
		startTime,
		endTime,
		latitude,
		longitude,
		address,
		tel,
		otherTel,
		website,
		telegram,
		instagram,
		subCategoryName,
		parentCategoryName,
		stateName,
		cityName,
		area,
		storeType,
		userId,
		dailyCapacity,
		ownerName
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateStore`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					storeId: storeId,
					name: name,
					rowNumber: rowNumber,
					offPercent: offPercent,
					summary: summary,
					notes: notes,
					dayOfWork: dayOfWork,
					startTime: startTime,
					endTime: endTime,
					latitude: latitude,
					longitude: longitude,
					address: address,
					tel: tel,
					otherTel: otherTel,
					website: website,
					telegram: telegram,
					instagram: instagram,
					subCategoryName: subCategoryName,
					parentCategoryName: parentCategoryName,
					stateName: stateName,
					cityName: cityName,
					area: area,
					storeType: storeType,
					userId: userId,
					dailyCapacity: dailyCapacity,
					ownerName: ownerName,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateService
	this.updateService = async function (
		serviceId,
		storeId,
		code,
		name,
		price,
		status
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateService`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					serviceId: serviceId,
					storeId: storeId,
					code: code,
					name: name,
					price: price,
					status: status,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> completeServiceOrder
	this.completeServiceOrder = async function (isStore, serviceOrderId) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/CompleteServiceOrder`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					isStore: isStore,
					serviceOrderId: serviceOrderId,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> updateFoodMenu
	this.updateFoodMenu = async function (
		foodMenuId,
		residenceId,
		mealTime,
		name,
		unitPrice
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateFoodMenu`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					foodMenuId: foodMenuId,
					residenceId: residenceId,
					mealTime: mealTime,
					name: name,
					unitPrice: unitPrice,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateResidence
	this.updateResidence = async function (
		residenceId,
		cityId,
		name,
		address,
		latitude,
		longtitude,
		tel,
		laws,
		startTime,
		endTime,
		totalCapacity,
		status
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateResidence`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					residenceId: residenceId,
					cityId: cityId,
					name: name,
					address: address,
					latitude: latitude,
					longtitude: longtitude,
					tel: tel,
					laws: laws,
					startTime: startTime,
					endTime: endTime,
					totalCapacity: totalCapacity,
					status: status,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateResidenceLimit
	this.updateResidenceLimit = async function (
		residenceLimitId,
		residenceId,
		maxTotal,
		startDate,
		endDate,
		createdOn
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateResidenceLimit`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					residenceLimitId: residenceLimitId,
					residenceId: residenceId,
					maxTotal: maxTotal,
					startDate: startDate,
					endDate: endDate,
					createdOn: createdOn,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateResidenceLimitStates
	this.updateResidenceLimitStates = async function (residenceLimitStates) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateResidenceLimitStates`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify(residenceLimitStates),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateResidenceRoomType
	this.updateResidenceRoomType = async function (
		residenceRoomTypeId,
		residenceId,
		roomTypeId,
		total,
		notes,
		personPrice,
		extraPersonPrice
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateResidenceRoomType`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					residenceRoomTypeId: residenceRoomTypeId,
					residenceId: residenceId,
					roomTypeId: roomTypeId,
					total: total,
					notes: notes,
					personPrice: personPrice,
					extraPersonPrice: extraPersonPrice,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateRoom
	this.updateRoom = async function (
		roomId,
		residenceRoomTypeId,
		code,
		name,
		notes,
		capacity,
		status
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateRoom`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					roomId: roomId,
					residenceRoomTypeId: residenceRoomTypeId,
					code: code,
					name: name,
					notes: notes,
					capacity: capacity,
					status: status,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> updateRoomType
	this.updateRoomType = async function (roomTypeId, name, totalCapacity) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateRoomType`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					roomTypeId: roomTypeId,
					name: name,
					totalCapacity: totalCapacity,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> addEntityImage
	this.addEntityImage = async function (entityName, storeId, file) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			let formData = new FormData();
			formData.append("file", file);
			formData.append("entityName", entityName);
			formData.append("entityId", storeId);
			formData.append("keyName", "Image");

			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}File/Upload`,
				headers: {
					accept: "application/json",
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
				data: formData,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
}
