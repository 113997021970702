import "../styles/login.css";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import UserController from "../services/user-controller";
import { emit, getToken, setToken } from "../utils/utility";
import { useNavigate } from "react-router-dom";
import { InputOtp } from "primereact/inputotp";

export default function Login(props) {
	const { t } = useTranslation();
	const localizer = t;
	const [isLoading, setIsLoading] = useState(false);
	const [pageType, setPageType] = useState("login"); //register-forget-change
	const [mobile, setMobile] = useState("");
	const [userPassword, setUserPassword] = useState("");
	const [verifyCode, setVerifyCode] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [errors, setErrors] = useState([]);
	const [canSendAgain, setCanSendAgain] = useState(false);
	const userController = new UserController();
	const navigate = useNavigate();

	const startTimer = function () {
		window.waitForResend = 60;
		if (window.timerInterval) clearInterval(window.timerInterval);
		window.timerInterval = setInterval(() => {
			window.waitForResend--;
			if (document.querySelector("span.p-badge-danger"))
				document.querySelector("span.p-badge-danger").innerHTML =
					window.waitForResend.toString();
			if (window.waitForResend <= 0) {
				clearInterval(window.timerInterval);
				setCanSendAgain(true);
			}
		}, 1000);
	};

	const changePageType = function (newPageType){
		setErrors([]);
		setPageType(newPageType);
	}

	const login = async function () {
		try {
			//>
			let errors = [];
			if (!mobile) errors.push("شماره موبایل را وارد کنید");
			if (!userPassword) errors.push("رمز عبور را وارد کنید");
			if (errors.length > 0) throw errors.join("\r\n");
			//>
			setIsLoading(true);
			setErrors([]);
			setVerifyCode(verifyCode);
			const result = await userController.login(mobile, userPassword);
			if (result.isSuccess === false) throw result.message;
			//>
			applyToken(result.value);
		} catch (error) {
			setErrors(error.toString().split("\r\n"));
		}
		setIsLoading(false);
	};

	const register = async function () {
		try {
			//>
			let errors = [];
			if (!mobile) errors.push("شماره موبایل را وارد کنید");
			if (!password) errors.push("رمز عبور را وارد کنید");
			if (!confirmPassword) errors.push("تکرار رمز عبور را وارد کنید");
			if(password !== confirmPassword)
			if (!newPassword) errors.push("رمز عبور و تکرار رمز عبور باید یکسان باشند");

			if (errors.length > 0) throw errors.join("\r\n");
			//>
			setIsLoading(true);
			setErrors([]);
			setVerifyCode(verifyCode);
			const result = await userController.register(
				mobile,
				password,
				confirmPassword
			);
			if (result.isSuccess === false) throw result.message;
			//>
			applyToken(result.value);
		} catch (error) {
			setErrors(error.toString().split("\r\n"));
		}
		setIsLoading(false);
	};

	const forgetPassword = async function () {
		try {
			//>
			let errors = [];
			if (!mobile) errors.push("شماره موبایل را وارد کنید");
			 
			if (errors.length > 0) throw errors.join("\r\n");
			//>
			setIsLoading(true);
			setErrors([]);
			const result = await userController.forgetPassword(mobile);
			if (result.isSuccess === false) throw result.message;

			setPageType("change");
			setCanSendAgain(false);
			startTimer(60);
		} catch (error) {
			setErrors(error.toString().split("\r\n"));
		}
		setIsLoading(false);
	};

	const changePassword = async function () {
		try {
			//>
			let errors = [];
			if (!verifyCode || verifyCode.length !== 4) errors.push("کد امنیتی را وارد کنید");
			if (!mobile) errors.push("شماره موبایل را وارد کنید");
			if (!newPassword) errors.push("رمز عبور را وارد کنید");
			if (!confirmNewPassword) errors.push("تکرار رمز عبور را وارد کنید");
			if(newPassword !== confirmNewPassword)
			if (!newPassword) errors.push("رمز عبور و تکرار رمز عبور باید یکسان باشند");

			if (errors.length > 0) throw errors.join("\r\n");
			//>
			setIsLoading(true);
			setErrors("");
			setVerifyCode(verifyCode);
			const result = await userController.changePassword(
				mobile,
				verifyCode,
				newPassword,
				confirmNewPassword
			);
			if (result.isSuccess === false) throw result.message;
			//>
			applyToken(result.value);
		} catch (error) {
			setErrors(error.toString().split("\r\n"));
		}
		setIsLoading(false);
	};

	const applyToken = function (token) {
		setToken(token);
		let returnUrl = null;
		if (window.location.href.indexOf("returnUrl=") > 0)
			returnUrl = window.location.href.split("returnUrl=")[1];
		//<
		emit("refreshProfile", "");
		if (returnUrl) {
			navigate(returnUrl, { replace: true });
		} else {
			navigate("/register", { replace: true });
		}
	};

	if (getToken()) {
		window.location = "/#/membership";
		return <></>;
	}
	return (
		<article className="login">
			<div className="header">
				{pageType === "login" && localizer("Login in club")}
				{pageType === "register" && localizer("Register in club")}
				{pageType === "forget" && localizer("Forget password")}
				{pageType === "change" && localizer("Change password")}
			</div>
			<div>
				{pageType === "login" && (
					<>
						<div>
							<label>{localizer("Mobile number")}</label>
							<InputText
								value={mobile}
								onChange={(e) => setMobile(e.target.value)}
								maxLength="11"
								keyfilter="int"
								disabled={isLoading}
							/>
						</div>
						<div>
							<label>{localizer("Password")}</label>
							<Password
								value={userPassword}
								onChange={(e) => setUserPassword(e.target.value)}
								disabled={isLoading}
							/>
						</div>
						<div>
							<Button
								label={localizer("Login")}
								onClick={() => login()}
								loading={isLoading}
							/>
						</div>
						<div>
							<Button
								label={localizer("I not register yet")}
								onClick={() => changePageType("register")}
								loading={isLoading}
								outlined
							/>
						</div>
						<div>
							<Button
								label={localizer("I forget password")}
								onClick={() => changePageType("forget")}
								loading={isLoading}
								outlined
							/>
						</div>
					</>
				)}
				{pageType === "register" && (
					<>
						<div>
							<label>{localizer("Mobile number")}</label>
							<InputText
								value={mobile}
								onChange={(e) => setMobile(e.target.value)}
								maxLength="11"
								keyfilter="int"
								disabled={isLoading}
							/>
						</div>
						<div>
							<label>{localizer("Password")}</label>
							<Password
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								disabled={isLoading}
							/>
						</div>
						<div>
							<label>{localizer("Confrim password")}</label>
							<Password
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								disabled={isLoading}
							/>
						</div>
						<div>
							<Button
								label={localizer("Register")}
								onClick={() => register()}
								loading={isLoading}
							/>
						</div>
						<div>
							<Button
								label={localizer("Alrealy registered")}
								onClick={() => changePageType("login")}
								loading={isLoading}
								outlined
							/>
						</div>
					</>
				)}
				{pageType === "forget" && (
					<>
						<div>
							<label>{localizer("Mobile number")}</label>
							<InputText
								value={mobile}
								onChange={(e) => setMobile(e.target.value)}
								maxLength="11"
								keyfilter="int"
								disabled={isLoading}
							/>
						</div>
						<div>
							<Button
								label={localizer("Confirm mobile")}
								onClick={() => forgetPassword()}
								loading={isLoading}
							/>
						</div>
						<div>
							<Button
								label={localizer("Cancel")}
								onClick={() => changePageType("login")}
								loading={isLoading}
								outlined
							/>
						</div>
					</>
				)}
				{pageType === "change" && (
					<>
						<p>
							{localizer("Enter verify code sent to ")}
							<br />
							<b>{mobile}</b>
						</p>
						<div className="flex">
							<InputOtp
								value={verifyCode}
								onChange={(e) => setVerifyCode(e.value)}
								integerOnly
							/>
						</div>

						<div className="send-again">
							<Button
								label={localizer("Send again")}
								icon="pi pi-send"
								badge={60}
								badgeClassName="p-badge-danger"
								onClick={() => forgetPassword()}
								outlined
								disabled={!canSendAgain}
							/>
							<Button
								label={localizer("Edit mobile number")}
								icon="pi pi-file-edit"
								onClick={() => changePageType("forget")}
								outlined
							/>
						</div>

						<div>
							<label>{localizer("New password")}</label>
							<Password
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								disabled={isLoading}
							/>
						</div>
						<div>
							<label>{localizer("Confrim new password")}</label>
							<Password
								value={confirmNewPassword}
								onChange={(e) => setConfirmNewPassword(e.target.value)}
								disabled={isLoading}
							/>
						</div>
						<div>
							<Button
								label={localizer("Change password")}
								onClick={() => changePassword()}
								loading={isLoading}
							/>
						</div>
						<div>
							<Button
								label={localizer("Cancel")}
								onClick={() => changePageType("login")}
								loading={isLoading}
								outlined
							/>
						</div>
					</>
				)}
				{errors.length > 0  && <div className="errors">{errors.map(item=> <p>{item}</p>)}</div>}
			</div>
		</article>
	);
}
