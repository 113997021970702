import "../styles/contact-us.css";
import React from "react";

export default function ContactUs(props) {
  return (
    <div>
      <h3>اطلاعات تماس</h3>
      <dl
        className="contact-address dl-horizontal"
        itemprop="address"
        itemscope=""
        itemtype="https://schema.org/PostalAddress"
      >
        <dt>
          <span>آدرس: </span>
        </dt>

        <dd>
          <span itemprop="streetAddress">
            تهران خیابان شهید بهشتی ظلع جنوبی میدان تختی وزارت تعاون و کار و
            امور رفاهی ، طبقه اول ، دبیرخانه هیات امناء مجموعه های ورزشی و
            تفریحی
            <br />
          </span>
        </dd>

        <dt>
          <span>تلفن: </span>
        </dt>
        <dd>
          <span itemprop="telephone">021-88520154 </span>
        </dd>
        <dt>
          <span>سامانه پیامک: </span>
        </dt>
        <dd>
          <span itemprop="telephone">100045191000 </span>
        </dd>
      </dl>
    </div>
  );
}
