import "../styles/stores.css";
import React, { useState, useEffect } from "react";
import ServiceController from "../services/service-controller";
import { Skeleton } from "primereact/skeleton";

export default function Stores(props) {
	const serviceController = new ServiceController();
	const [isLoading, setIsLoading] = useState(false);
	const [stores, setStores] = useState([]);

	const getData = async function () {
		try {
			setIsLoading(true);
			let category = "";
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				category = urlParams.get("cat");
			}
			const storesResult = await serviceController.getStores(category, 1, 12);
			if (storesResult.isSuccess === false) throw storesResult.message;
			setStores(storesResult.value);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div>
			<h3>فروشگاه ها</h3>
			{isLoading === true && (
				<>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
					</div>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
					</div>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
					</div>
				</>
			)}
			<ul className="stores">
				{stores.map((item) => (
					<li>
						<img src={item.imageUrl} alt="" />
						<div>
							<h2>{item.name}</h2>
							<p>{item.offPercent} % </p>
						</div>
						<a href={`/#/store-details/?name=${item.name}`}>
							مشاهده جزییات بیشتر...
						</a>
					</li>
				))}
			</ul>
		</div>
	);
}
