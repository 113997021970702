import "../styles/section-sports.css";
import React, { useState, useEffect } from "react";
import ServiceController from "../services/service-controller";
import { Skeleton } from "primereact/skeleton";
import { API_BASE_URL } from "../utils/utility";

export default function SectionSports(props) {
	const serviceController = new ServiceController();
	const [isLoading, setIsLoading] = useState(false);
	const [stores, setStores] = useState([]);
	const [services, setServices] = useState([]);
	const [pageType, setPageType] = useState("stores");

	const getData = async function () {
		try {
			setIsLoading(true);
			const storesResult = await serviceController.getSportStores(1, 100);
			if (storesResult.isSuccess === false) throw storesResult.message;
			setStores(storesResult.value);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	//> viewServices
	const viewServices = async function (storeId) {
		try {
			setIsLoading(true);
			const servicesResult = await serviceController.getStoreServices(storeId);
			if (servicesResult.isSuccess === false) throw servicesResult.message;
			setServices(servicesResult.value);
			setPageType("services");
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};
	//>
	return (
		<div>
			<h3>مراکز ورزشی</h3>
			{isLoading === true && (
				<>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
					</div>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
					</div>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
					</div>
				</>
			)}
			{isLoading === false && pageType === "stores" && (
				<ul className="sports">
					{stores.map((item) => (
						<li>
							<a onClick={() => viewServices(item.storeId)}>
								<img src={API_BASE_URL + item.imageUrl} alt="" />
								<h2>{item.name}</h2>
							</a>
						</li>
					))}
				</ul>
			)}
			{isLoading === false && pageType == "services" && (
				<ul className="services">
					{services.map((item) => (
						<li>
							<a href={"/#/buy-service/?code=" + item.code}>
								<img src={API_BASE_URL + item.imageUrl} alt="" />
								<div>
									<h2>{item.name}</h2>
									<span>{item.code}</span>
								</div>
								<h3>{item.price.toLocaleString("fa-IR")} ریال</h3>
							</a>
						</li>
					))}
				</ul>
			)}
		</div>
	);
}
