import "../styles/locations.css";
import React, { useState, useEffect } from "react";
import ServiceController from "../services/service-controller";

export default function SectionLocations(props) {
  const serviceController = new ServiceController();
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState([]);

  const getData = async function () {
    try {
      setIsLoading(true);
      const citiesResult = await serviceController.getCities();
      if (citiesResult.isSuccess === false) throw citiesResult.message;
      setCities(citiesResult.value);
    } catch (error) {
      alert(error.toString());
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <h2 className="head">اماکن اقامتی</h2>
      <ul className="location-cities">
        {isLoading === false &&
          cities.map((item) => (
            <li>
              <a href={`/#/rooms/?city=${item.name}`}>
                <img src={item.imageUrl} loading="lazy" alt="" />
                <span>{item.name}</span>
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
}
