import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
//
import "./styles/App.css";
import { getToken,  subscribe } from "./utils/utility";
import Login from "./views/login";
import Payment from "./views/payment";
import CallBack from "./views/call-back";
import { Route, Routes } from "react-router-dom";
import Home from "./views/home";
import Register from "./views/register";
import About from "./views/about";
import Levels from "./views/levels";
import ContactUs from "./views/contact-us";
import SectionSports from "./views/section-sports";
import SectionStores from "./views/section-stores";
import SectionLocations from "./views/section-locations";
import LevelBronze from "./views/level-bronze";
import LevelSilver from "./views/level-silver";
import LevelGold from "./views/level-gold";
import LevelPlatinum from "./views/level-platinum";
import Rooms from "./views/rooms";
import RoomDetails from "./views/room-details";
import AddNewQueue from "./views/add-new-queue";
import Stores from "./views/stores";
import StoreDetails from "./views/store-details";
import { PrimeIcons } from "primereact/api";
import UserController from "./services/user-controller";
import { useEffect, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Admin from "./views/admin";
import Users from "./views/users";
import EditStores from "./views/edit-stores";
import EditServices from "./views/edit-services";
import EditLevels from "./views/edit-levels";
import EditResidences from "./views/edit-residences";
import EditResidenceLimits from "./views/edit-residence-limits";
import MemberShip from "./views/membership";
import BuyService from "./views/buy-service";
import ViewServiceOrders from "./views/view-service-orders";

function App() {
	const token = getToken();
	const userController = new UserController();
	const [profile, setProfile] = useState({});
	const [isProfileShow, setIsProfileShow] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	//> getData
	const getData = async function () {
		const profileResult = await userController.getProfile();
		if (profileResult.isSuccess === false) {
			localStorage.clear();
		} else {
			setProfile(profileResult.value);
			setIsAdmin(await userController.isAdminUser());
		}
	};

	subscribe("refreshProfile", (data) => {
		getData();
	});

	//> useEffect
	useEffect(() => {
		getData();
	}, []);

	const confirmSignOut = function () {
		setIsProfileShow(false);
		confirmDialog({
			message: "آیا مطمئن به خروج هستید؟",
			header: "تایید خروج",
			icon: "pi pi-exclamation-triangle",
			defaultFocus: "accept",
			acceptLabel: "بله",
			rejectLabel: "نه",
			accept,
			reject,
		});
	};

	const accept = function () {
		localStorage.clear();
		window.location = "/";
	};

	const reject = function () {};

	return (
		<article
			className="main"
			onClick={(e) => {
				if (!e.target.getAttribute("data-profile-menu"))
					setIsProfileShow(false);
			}}
		>
			<ConfirmDialog />
			<header>
				<div className="top-banner">
					<img src="/images/b1.jpg" alt="" />
					<div>
						<h2>سامانه جامع باشگاه مشتریان کارگران</h2>
						<p>هیات امناء مجموعه های ورزشی و تفریحی کارگران کشور</p>
					</div>
					<img src="/images/iran.png" alt=""/>
				</div>

				<div className="menu-bar">
					<ul className="main-menu">
						<li>
							<a href="/#">خانه</a>
						</li>
						<li>
							<a href="/#/about">معرفی</a>
						</li>
						<li>
							<a href="/#/stores">فروشگاه ها</a>
						</li>
						<li>
							<a href="/#/login">عضویت</a>
						</li>
						<li>
							<a href="/#/contact-us">تماس با ما</a>
						</li>
					</ul>

					{token && (
						<>
							<div className="profile">
								<div
									onClick={() => setIsProfileShow(true)}
									data-profile-menu="true"
								>
									<a className={PrimeIcons.USER} data-profile-menu="true"></a>
									<div data-profile-menu="true">
										<span data-profile-menu="true">{profile.fullName}</span>
										<b data-profile-menu="true">{profile.mobile}</b>
									</div>
									<i
										className={PrimeIcons.ANGLE_DOWN}
										data-profile-menu="true"
									></i>
								</div>

								<ol
									data-profile-menu="true"
									className={
										isProfileShow === true
											? "profile-menu show"
											: "profile-menu"
									}
								>
									<li data-profile-menu="true">
										<a
											href="/#/register"
											data-profile-menu="true"
											onClick={() => setIsProfileShow(false)}
										>
											<i
												className={PrimeIcons.USER_EDIT}
												data-profile-menu="true"
											></i>
											<span data-profile-menu="true">ویرایش پروفایل</span>
										</a>
									</li>
									{isAdmin === true && (
										<li data-profile-menu="true">
											<a
												href="/#/admin"
												data-profile-menu="true"
												onClick={() => setIsProfileShow(false)}
											>
												<i
													className={PrimeIcons.LIST}
													data-profile-menu="true"
												></i>
												<span data-profile-menu="true">پنل مدیریت</span>
											</a>
										</li>
									)}
									<li data-profile-menu="true">
										<a
											href="javascript:void(0);"
											onClick={confirmSignOut}
											data-profile-menu="true"
										>
											<i
												className={PrimeIcons.SIGN_OUT}
												data-profile-menu="true"
											></i>
											<span data-profile-menu="true">خروج</span>
										</a>
									</li>
								</ol>
							</div>
						</>
					)}
					<div className="back">
						<a href="#" onClick={() => window.history.back()}>
							<i className={PrimeIcons.ANGLE_LEFT} alt=""></i>
						</a>
					</div>
				</div>
			</header>

			<div className="content">
				<Routes>
					<Route path="/" Component={Home} exact />
					<Route path="/home" Component={Home} exact />
					<Route path="/login" Component={Login} exact />
					<Route path="/register" Component={Register} exact />
					<Route path="/membership" Component={MemberShip} exact />
					<Route path="/payment" Component={Payment} exact />
					<Route path="/call-back" Component={CallBack} exact />
					<Route path="/about" Component={About} exact />
					<Route path="/levels" Component={Levels} exact />
					<Route path="/stores" Component={Stores} exact />
					<Route path="/contact-us" Component={ContactUs} exact />
					<Route path="/section-locations" Component={SectionLocations} exact />
					<Route path="/section-sports" Component={SectionSports} exact />
					<Route path="/section-stores" Component={SectionStores} exact />
					<Route path="/level-bronze" Component={LevelBronze} exact />
					<Route path="/level-silver" Component={LevelSilver} exact />
					<Route path="/level-gold" Component={LevelGold} exact />
					<Route path="/level-platinum" Component={LevelPlatinum} exact />
					<Route path="/rooms" Component={Rooms} exact />
					<Route path="/room-details" Component={RoomDetails} exact />
					<Route path="/add-new-queue" Component={AddNewQueue} exact />
					<Route path="/store-details" Component={StoreDetails} exact />
					<Route path="/buy-service" Component={BuyService} exact />
					<Route path="/view-service-orders" Component={ViewServiceOrders} exact />
					{isAdmin && (
						<>
							<Route path="/admin" Component={Admin} exact />
							<Route path="/users" Component={Users} exact />
							<Route path="/edit-stores" Component={EditStores} exact />
							<Route path="/edit-services" Component={EditServices} exact />
							<Route path="/edit-levels" Component={EditLevels} exact />
							<Route path="/edit-residences" Component={EditResidences} exact />
							<Route
								path="/edit-residence-limits"
								Component={EditResidenceLimits}
								exact
							/>
						</>
					)}
				</Routes>
			</div>

			<footer>
				<div className="links">
					<div>
						<h2>در مورد سامانه جامع باشگاه مشتریان کارگران</h2>
						<p></p>
						<ul>
							<li>
								<a href="/#/about">چه جوری کار می کنه؟</a>
							</li>
							<li>
								<a href="/#/about">چه جوری خرید کنم؟</a>
							</li>
							<li>
								<a href="/#/about">چه جوری عضو بشم؟ّ</a>
							</li>
						</ul>
					</div>

					<div>
						<h2>دسترسی سریع</h2>
						<ul>
							<li>
								<a href="/#/stores">جستجوی فروشگاه ها</a>
							</li>
							<li>
								<a href="/#/login">عضویت</a>
							</li>
							<li>
								<a href="/#/contact-us">اطلاعات تماس</a>
							</li>
						</ul>
					</div>

					<div>
						<h2>پیوند ها</h2>
						<ul>
							<li>
								<a href="https://www.mcls.gov.ir/" target="_blank" rel="noreferrer">
									وزارت تعاون ، کار و رفاه اجتماعی
								</a>
							</li>
							<li>
								<a href="https://sport.mcls.gov.ir/" target="_blank" rel="noreferrer">
									اداره کل امور ورزشی | وزارت تعاون ، کار و رفاه اجتماعی
								</a>
							</li>
						</ul>
					</div>

					<div>
						<a
							referrerpolicy="origin"
							target="_blank"
							href="https://trustseal.enamad.ir/?id=544056&Code=V64HCegPTKGfTOGOUscQhxbYOsuYDRm2"
						>
							<img
								referrerpolicy="origin"
								src="https://trustseal.enamad.ir/logo.aspx?id=544056&Code=V64HCegPTKGfTOGOUscQhxbYOsuYDRm2"
								code="V64HCegPTKGfTOGOUscQhxbYOsuYDRm2"
							/>
						</a>
					</div>
				</div>

				<p>
					© 2024 کلیه حقوق این سایت متعلق به هیات امناء مجموعه های ورزشی و
					تفریحی کارگران کشور می باشد . طراحی و بهینه سازی سایت : شبکه سفید
				</p>
			</footer>
		</article>
	);
}

export default App;
