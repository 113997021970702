import "../styles/about.css";
import React from "react";

export default function About(props) {

  return (
      <div itemprop="articleBody">
        <h3>معرفی سامانه جامع باشگاه مشتریان کارگران :</h3>
        <p>
          &#x202B;هیات امناء مجموعه ورزشی و تفریحی کارگران کشور با هدف ارائه خدمات متعدد و ویژه از طریق مراکز ورزشی و تفریحی
          &nbsp;تحت پوشش و سایر مراکز طرف قرارداد به کارگران گرامی اقدام به راه
          اندازی سامانه جامع باشگاه مشتریان کارگران نموده است .
        </p>
        <p>
          در همین راستا &nbsp;سامانه جامع باشگاه مشتریان کارگران با در اختیار داشتن مراکز
          ورزشی و تفریحی در اقصا نقاط کشور و نیز جمع آوری فروشگاه های متنوع و
          متعدد &#x202B;در اصناف مختلف اقدام به ارائه خدمات تخفیف هوشمند و
          هدفمند به مشتریان خود &#x202B;می نماید.
        </p>
        <p>مزایای عضویت در سامانه جامع باشگاه مشتریان کارگران :</p>
        <ul>
          <li>
            &#x202B;ارایه تخفیف در طول 365 روز سال از سوی مراکز اختصاصی تحت پوشش
            و تمام فروشندگان طرف قرارداد سامانه و بهره مندی از کلیه امکانات دیگر
            سامانه در طول سال.&#x202B;
          </li>
          <li>
            امکان خرید اقساطی و اعتباری برای کارگران از طریق مراکز طرف قرارداد
          </li>
          <li>
            عدم نیاز به صدور کارت تخفیف جدید به منظور کاهش هزینه های ثبت نام و
            حذف مشکل به یاد سپاری &#x202B;رمز عبور کارت های وفاداری جدید.
          </li>
          <li>
            &#x202B; ارائه خدمات تخفیفی ویژه و در اختیار گذاشتن امکانات خاص جهت
            اقشار کم درآمد
          </li>
          <li>
            &#x202B;- امکان تعریف و افزودن انواع کارت های بانکی شبکه شتاب و حساب
            کاربری و کیف پول اختصاصی
          </li>
          <li>&#x202B;- قابلیت استفاده از خدمات سامانه در سراسر کشور</li>
          <li>
            &#x202B;- خرید و دریافت تخفیف بصورت آنی و آنلاین با کارت های عضو
            سامانه جامع باشگاه مشتریان کارگران از فروشگاه ها و مراکز طرف قرارداد.
          </li>
          <li>
            &#x202B;- عدم بلوکه کردن وجه تراکنشهای فروشندگان در پرداخت آفلاین
            (رفع مشکل مدلهای رایج در کشور).
          </li>
          <li>
            &#x202B;- رعایت استاندارد های خاص در انتخاب عرضه کنندگان کالا و
            خدمات به منظور حفظ کرامت و ارزش &#x202B;مشتریان گرامی.
          </li>
          <li>
            &#x202B;- &#x202B;- پاسخگویی به سواالت و مشکالت مشتریان از طریق مرکز
            تماس، سایت و اپلیکیشن موبایل به صورت&#x202B;42 ساعته در تمام روزهای
            هفته بدون تعطیلی ) 42 × 7 × 563 )
          </li>
          <li>
            &#x202B;- مشارکت مشتریان در بهبود عملکرد سامانه از طریق سیستم تیکت
            گذاری.
          </li>
          <li>
            &#x202B;- ارائه انواع طرح های تشویقی و اهداء جوایز به مناسبتهای
            مختلف بر اساس سیستم امتیاز دهی.
          </li>
          <li>
            &#x202B;- پایش عملکرد فروشندگان بر اساس نظرات مشتریان و بازرسان
            سامانه.
          </li>
        </ul>
        <p>مزایای سامانه جامع باشگاه مشتریان کارگران برای پذیرندگان :</p>
        <ul>
          <li>
            عقد قرارداد انحصاری با پذیرندگان در محدوده مکانی مشخص ، با توجه به
            صنف شغلی طرف قرارداد.
          </li>
          <li>
            تبلیغات و اطلاع رسانی مراکز پذیرنده از طریق سایت ،اپلیکسشن ،شیکه های
            اجتماعی (کانال اختصاصی) ، جراید و غیره
          </li>
          <li>نصب رایگان دستگاه کارتخوان چند منظوره</li>
        </ul>
        <p>ویژگی ها و زیر ساخت های نرم افزاری و سخت افزاری :</p>
        <p>
          &#x202B;اتصال به شبکه بانکی کشور به واسطه شرکت &nbsp;های&#x202A; PSP
          &nbsp;انجام شده و نرم افزار مورد استفاده &#x202B;در سامانه تخفیف
          &nbsp;سامانه جامع باشگاه مشتریان کارگران در لایه ای قبل از شبکه شاپرک بانک مرکزی
          نصب و امکان اتصال دستگاههای &#x202B;کارتخوان بانکی &nbsp;سامانه
          مشتریان کارگران به هر حساب بانکی و عضویت هر کارت عضو شبکه شتاب در
          سامانه میسر گردیده &#x202B;است همچنین &#x202B;&#x202B;نرم افزار
          &#x202A; CRM&nbsp; قدرتمند سامانه به عنوان هسته مرکزی تبادل اطلاعات
          &#x202B;بین سوئیچ بانکی، مشتریان و فروشندگان و همچنین ارتباط بین وب
          سایت &#x202B;سامانه، اپلیکیشن موبایلی و نرم افزار &#x202A; CRMطراحی و
          به کار گرفته شده است و &#x202B;&#x202B;به منظور ارائه بهترین خدمات به
          مشتریان و فروشندگان سامانه، از &#x202B;بهترین سرورها، تجهیزات شبکه و
          سایر سخت افزارها بهره گرفته ایم.
        </p>
        <p>&#x202B;&#x202B;مراکز طرف قرارداد&nbsp; :</p>
        <p>
          &#x202B;ما در انتخاب پذیرندگان خود با دقت آنها را مورد ارزیابی کیفی و
          کمی قرار داده ایم. این پذیرندگان &#x202B;روز به روز در حال افزایش
          هستند و در حال حاضر بالغ به 20000 فروشگاه و مراکز خدماتی در سطح کشور
          &#x202B;می باشند. گستره صنوف و حوزه فعالیت این مراکز بسیار زیاد است و
          شامل مراکز تفریحی، ورزشی، درمانی، &nbsp;&nbsp;&#x202B;توریستی،
          رستورانها، پوشاک و فروشگاه های مواد غذایی و ... است.
        </p>
        <p>&#x202B;</p>
      </div>
  );
}
