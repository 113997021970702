import "../styles/payment.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import PaymentController from "../services/payment-controller";
import { getToken } from "../utils/utility";

export default function Payment(props) {
	const { t } = useTranslation();
	const localizer = t;
	const [isLoading, setIsLoading] = useState(false);
	const [payment, setPayment] = useState({
		paymentId: "",
		mobile: "",
		nationalId: "",
		baseAmount: 0,
		levelName: "",
		decile: 0,
		offPercent: 0,
		discount: 0,
		totalAmount: 0,
	});
	const [errorMessage, setErrorMessage] = useState("");
	//
	const [method, setMethod] = useState(null);
	const [url, setUrl] = useState(null);
	const [formData, setFormData] = useState({});

	const paymentController = new PaymentController();
	let counter = 0;

	useEffect(() => {
		getData();
	}, []);

	const getData = async function () {
		try {
			let decile = 1;
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				decile = urlParams.get("decile");
			}
			if (!getToken()) return;
			if (window.isBusy === true) return;
			setIsLoading(true);
			window.isBusy = true;
			var result = await paymentController.viewMembershipPayment(decile);
			if (result.isSuccess === false) throw result.message;
			setPayment(result.value);
		} catch (error) {
			setErrorMessage(error.toString());
		}
		setIsLoading(false);
		window.isBusy = false;
	};

	const sendPayment = async function () {
		try {
			setIsLoading(true);
			setErrorMessage("");
			const result = await paymentController.doPayment(payment.paymentId);
			if (result.isSuccess === false) throw result.message;
			setMethod(result.value.method);
			setUrl(result.value.url);
			setFormData(result.value.formData);
			setTimeout(() => {
				document.querySelector("#paymentForm").submit();
			}, 2000);
		} catch (error) {
			setErrorMessage(error.toString());
		}
		setIsLoading(false);
	};

	//>
	if (!getToken()) {
		//navigate("/login?returnUrl=/membership", { replace: true });
		window.location = "/#/login?returnUrl=/membership";
		return <></>;
	}
	return (
		<article className="payment">
			<div className="header">{localizer("Payment page")}</div>
			<div className="invoice">
				<ul>
					<li>
						<label>{localizer("Mobile number")}</label>
						<span>{payment.mobile}</span>
					</li>
					<li>
						<label>{localizer("National code")}</label>
						<span>{payment.nationalId}</span>
					</li>
					<li>
						<label>دهک بندی شما</label>
						<span>دهک {payment.decile}</span>
					</li>
					<li>
						<label>سطح امتیاز</label>
						<span>{payment.levelName}</span>
					</li>
				</ul>
				<table>
					<thead>
						<tr>
							<th>#</th>
							<th>{localizer("Desription")}</th>
							<th>{localizer("Amount")}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{(++counter).toLocaleString("fa-IR")}</td>
							<td>{localizer("Amount of customers clup for 1 year")}</td>
							<td>
								{payment.baseAmount.toLocaleString("fa-IR")} {localizer("Rial")}
							</td>
						</tr>
						{payment.offPercent > 0 && (
							<>
								<tr>
									<td>{(++counter).toLocaleString("fa-IR")}</td>
									<td>{localizer("Percent of discount")}</td>
									<td>
										{payment.offPercent.toLocaleString("fa-IR")}{" "}
										{localizer("Percent")}
									</td>
								</tr>
								<tr>
									<td>{(++counter).toLocaleString("fa-IR")}</td>
									<td>{localizer("Amount of discount")}</td>
									<td>
										{payment.discount.toLocaleString("fa-IR")}{" "}
										{localizer("Rial")}
									</td>
								</tr>
							</>
						)}
					</tbody>
					<tfoot>
						<tr>
							<td></td>
							<td>{localizer("Payable amount")}</td>
							<td>
								{payment.totalAmount.toLocaleString("fa-IR")}{" "}
								{localizer("Rial")}
							</td>
						</tr>
					</tfoot>
				</table>
				<div>
					<Button
						label={localizer("Payment")}
						onClick={() => sendPayment()}
						loading={isLoading}
					/>
				</div>
			</div>
			<div>
				{errorMessage && <div className="text-danger">{errorMessage}</div>}
			</div>

			<form id="paymentForm" method={method} action={url}>
				{Object.keys(formData).map((item) => (
					<input type="hidden" name={item} value={formData[item]} />
				))}
			</form>
		</article>
	);
}
